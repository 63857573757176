 .rt-auth-modal {
    overflow: hidden;

    &::after {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 50%;
      background: #fff url('/assets/marketplace-background.jpeg') no-repeat;
      background-size: cover;
      background-position: right center;
    }
 }

 .rt-auth-back {
    position: absolute;
    left: 0;
    top: 10px;
 }

 .rt-auth-panel {
    width: 332px;
    margin: 0 auto 50px;

 }
  
.rtz-registration-panel-access {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 6px;
  margin: 0 0 12px;
}
  
a.rtz-reg-bck {
  position: absolute;
  left: 20px;
  top: 22px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 36px;
  height: 36px;
  &::after {
    inset: 0;
    display: block;
    position: absolute;
    border-radius: 50%;
    background-color: $gray5;
    z-index: 0;
    content: ' ';
    transition: $transition;
    transform: scale3d(0,0,0);
  }
  &::before {
    position: relative;
    display: block;
    font: normal 400 12px/1 navus-platform;
    content: '\e605';
    color: $gray60;
    z-index: 1;
  }
  &:hover {
    &::after {
      transform: scale3d(1,1,1);
    }
  }
}

.rtz-reg-intro {
  position: relative;
  text-align: center;

  h1 {
    margin: 20px auto;
  }

  p {
    margin: 5px auto;
    font-size: 14px;
    line-height: 20px;
  }
} 
  
.rtz-registration-options {
  margin: 20px auto 0;
  width: 322px;
  display: block;
  ul {
    list-style: none;
    display: block;
    margin: 0;
    padding: 0;
    text-align: left;

    li {
      display: block;
      margin: 0;
      font-family: $headingFont;
      font-size: 15px;
      font-weight: 600;

      + li {
        margin-top: 8px;
      }

      a {
        text-decoration: none;
        display: block;
        border: 1px solid $gray22;
        background-color: #fff;
        border-radius: $configRadius;
        color: #000;
        position: relative;
        padding: 10px 10px 10px 78px;
        transition: background-color 220ms ease;
        &:before {
          display: block;
          position: absolute;
          height: 20px;
          left: 46px;
        }
        &.ro-google {
          &:before {
            content: ' ';
            background: url('/assets/registration-icon-set.png') no-repeat 0 0;
            background-size: 85px 20px;
            width: 20px;
            height: 20px;
          }
        }
        &.ro-fb {
          &:before {
            content: ' ';
            background: url('/assets/registration-icon-set.png') no-repeat -24px 0;
            background-size: 85px 20px;
            width: 10px;
            height: 20px;
            margin-left: 5px;
          }
        }
        &.ro-ms {
          &:before {
            content: ' ';
            background: url('/assets/registration-icon-set.png') no-repeat -38px 0;
            background-size: 85px 20px;
            width: 21px;
            height: 20px;
          }
        }
        &.ro-email {
          &:before {
            content: ' ';
            background: url('/assets/registration-icon-set.png') no-repeat -62px -1px;
            background-size: 85px 20px;
            width: 22px;
            height: 17px;
            margin-top: 2px;
          }
        }
        &:hover {
          background-color: $gray5;
        }
      }
    }
  }


  @media (max-width: 480px) {
    width: auto;
    ul li {
      a {
        padding: 10px 10px 10px 48px;
        &:before {
          left: 16px;
        }
      }
    }
  }
}

.rtz-registration-options.disabled {
  ul li a {
    pointer-events: none;
    &:before {
      filter: grayscale(100%);
      transition: filter 450ms ease;
    }
  }
}

.rt-checkbox-item {
  color: var(--Text-Body-Text, #5E5568);
  font-size: 14px;
  line-height: 20px;

  input[type="checkbox"] {
    position: relative;
    top: 3px;
    width: 16px;
    height: 16px;
  }

  a {
    color: var(--Text-Body-Text, #5E5568);
  }
}

.rt-switch-auth {
  margin: 20px 0 0;
  color: var(--Text-Body-Text, #5E5568);
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  a {
    color: var(--Text-Body-Text, #5E5568);
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
  }
}
  
.rtz-reg-msg {
  width: 600px;
  margin: 14px;
  border: 1px solid $gray22;
  background-color: #fff;
  border-radius: $configRadius;
  padding: 40px 30px 20px;
  text-align: center;
  max-width: 100%;
  h1 {
    font-size: 32px;
    margin: 0 0 15px;
  }
  p {
    color: $gray60;
    a {
      color: #000;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
  
.reg-footnote {
  font-size: 11px;
  color: $gray60;
  text-align: center;
  margin: 15px 0 0;
  a {
    color: $bodyColor;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

p.rtz-foo-dsc {
  position: absolute;
  inset: auto 0 0 0;
  font-size: 13px;
  text-align: center;
  color: $gray60;
  margin: 0 0 15px;
}

.rtz-from-req {
  font-size: 11px;
}

.rt-spacer {
  height: 24px;
}
  