// Main Layout
.rt-frame {
  position: relative;
  width: 100vw;
  height: 100vh;
  width: 100dvw;
  height: 100dvh;
  display: flex;
  flex-flow: column wrap;
  align-items: stretch;
  align-content: stretch;
  overflow: hidden;
  .rt-header {
    flex: initial;
    min-height: 0;
  }
  .rt-body {
    flex: auto;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    align-content: flex-start;
    padding: 4px;
  }
  .rtz-config-side-panel {
    flex: 0 0 260px;
    min-width: 0;
    background-color: #fff;
    padding-inline: 0 20px;
    position: relative;
    z-index: 2;
  }
  .rtz-config-stage {
    flex: auto;
    position: relative;
    background-color: #F1F1F1;
    border-radius: 16px;
    border-width: 4px;
    border-style: solid;
    border-color: #fff;
    overflow: hidden;
    .rtz-config-stage-sleeve {
      position: absolute;
      inset: 0;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 24px;
    }
  }
  
  @media (max-width: $tablet-screen) {
    .rtz-config-stage {
      .rtz-config-stage-sleeve {
        padding: 18px 14px;
      }
    }
  }
  @media (max-width: $phablet-screen) {
    .rtz-config-stage {
      .rtz-config-stage-sleeve {
        padding: 18px 10px;
      }
    }
  }
}
// /Main Layout

.rt-header {
  display: flex;
  justify-content: flex-start;
  padding: 12px 32px 4px;
  align-items: center;
  position: relative;
  z-index: 6;
  width: 100%;
  max-width: 1440px;
  margin: auto;
  .rtz-cf-logo-port {
    flex: auto;
    max-width: 230px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    figure {
      display: block;
      height: 22px;
      margin-left: 16px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .rtz-cf-user-settings {
    flex: initial;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;

    @media (max-width:980px) {
      margin-left: 0;
    }
  }
  @media (max-width: $tablet-screen) {
    padding: 18px 24px 12px 37px;
  }
}

// Config menu
.rtz-ui-menu {
  ul {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: block;
      font-family: $headingFont;
      font-size: 15px;
      font-weight: 600;
      margin: 1.5px 0;
      a {
        text-decoration: none;
        display: block;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 7px 14px;
        background-color: #fff;
        color: $bodyColor;
        border-radius: $configRadius;
        position: relative;
        &:hover {
          background-color: $gray5;
        }
        &[class^="rtz-ico-"] {
          padding: 7px 14px 7px 50px;
          transition: width 300ms linear, padding 300ms linear;
          &::before {
            display: block;
            font: normal 17px/1 navus-platform;
            position: absolute;
            left: 17px;
            top: 11px;
            color: $gray60;
          }
        }
        &.rtz-ico-settings {
          &:before {
            content: '\e904';
            font: normal 17px/1 riterz-ico;
          }
        }
        &.rtz-ico-profile {
          &:before {
            content: '\e9a0';
          }
        }
        &.rtz-ico-privacy {
          &:before {
            content: '\e99f';
          }
        }
        &.rtz-ico-legal {
          &:before {
            content: '\e99a';
          }
        }
        &.rtz-ico-signout {
          &:before {
            content: '\e99b';
          }
        }
        &.rtz-ico-users {
          &:before {
            content: '\e9a2';
          }
        }
        &.rtz-ico-subscriptions {
          &:before {
            content: '\e9a1';
          }
        }
        &.rtz-ico-billing {
          &:before {
            content: '\e999';
          }
        }
        &.rtz-ico-organisation {
          &:before {
            content: '\e99d';
          }
        }
        &.rtz-ico-dashboard {
          &:before {
            content: '\e9a3';
          }
        }
        &.rtz-ico-file-manager {
          &:before {
            content: '\e9a4';
            font-size: 15px;
          }
        }
        &.rtz-ico-news-manager {
          &:before {
            content: '\e9a7';
          }
        }
        &.rtz-ico-events {
          &:before {
            content: '\e9a6';
          }
        }
        &.rtz-ico-advertising {
          &:before {
            content: '\e9a5';
          }
        }
        &.rtz-ico-config-settings {
          &:before {
            content: '\e92a';
          }
        }
        &.rtz-ico-step-by-step {
          &:before {
            content: '\e019';
          }
        }
        &.rtz-ico-mobile-app {
          &:before {
            content: '\e010';
            font-size: 19px;
          }
        }
        &.active {
          color: $riterzSecondary;
          background-color: transparent;
          pointer-events: none;
          &::before {
            color: $riterzSecondary;
          }
        }
      }
      @at-root .last-child-alt#{&} {
        &:last-child {
          margin-top: 14px;
          padding-top: 16px;
          border-top: 1px solid $gray22;
        }
      }
    }
  }
}

// Menu collapsed
.no-touchevents {
  .side-menu-collapsed {
    .rtz-config-side-panel {
      animation: moveMenu 300ms forwards;
      .rtz-config-side-panel-up, .rtz-config-side-panel-down {
        .cm-organization {
          .cm-current-organization {
            &::after, .cm-organization-label {
              display: none;
              opacity: 0;
            }
          }
        }
        > .rtz-ui-menu, .rtz-ui-global-settings .rtz-ui-settings-box > .rtz-ui-menu:first-child {
          margin-inline: 0;
          transition: all 300ms linear;
          ul {
            max-width: 58px;
            padding: 0 0 0 8px;
            transition: all 300ms linear;
            li {
              overflow: hidden;
              max-height: 38px;
              max-width: 48px;
              transition: all 300ms linear;
              a {
                width: 0;
                &[class^=rtz-ico-] {
                  padding: 7px 0 7px 30px;
                  transition: width 200ms linear, padding 200ms linear;
                  &::before {
                    left: 5px;
                    transition: left 300ms linear;
                  }
                }
                &:hover {
                  background-color: transparent;
                  &:before {
                    color: $riterzPrimary;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Menu restored
.no-touchevents {
  .rtz-config-side-panel {
    animation: restoreMenu 300ms forwards;
  }
}

@keyframes moveMenu {
  0% {
    flex: 0 0 260px;
    padding: 0 24px;
  }
  100% {
    flex: 0 0 70px;
    padding: 0 10px;
  }
}

@keyframes restoreMenu {
  0% {
    flex: 0 0 70px;
    padding: 0 10px;
  }
  100% {
    flex: 0 0 260px;
    padding: 0 24px;
  }
}

.rtz-config-side-panel .cm-current-organization {
    background-color: #fff;
    position: relative;
    z-index: 3;
    border-bottom: 1px solid $gray10;
    padding: 14px 24px 14px 0;
    margin-inline: 20px;
    transition: all 200ms ease;
    &::after {
      content: '\e604';
      display: block;
      font: normal 400 12px/1 navus-platform;
      inset: 30px 0 auto auto;
      color: $gray60;
      position: absolute;
    }
}

.no-touchevents {
  .side-menu-collapsed {
    .rtz-config-side-panel .cm-organization {
      margin: 0;
      .cm-current-organization {
        margin-inline: 0;
        padding: 14px 0 14px 0;
        + .cm-organisation-list {
          right: auto;
          width: 260px;
          padding: 0;
        }
        .cm-organization-logo {
          margin: 0 auto;
        }
      }
    }
  }
}

.rtz-config-side-panel .cm-organization-search {
  border: 1px solid $gray10;
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba($bodyColor,0.4);
    opacity: 1; /* Firefox */
  }
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgba($bodyColor,0.4);
  }
  &::-ms-input-placeholder { /* Microsoft Edge */
    color: rgba($bodyColor,0.4);
  }
  &:focus, &:active {
    border: 1px solid rgba($riterzSecondary,0.3);
    outline: none;
    box-shadow: 0 0 0 3px rgba(lighten($riterzSecondary,40),0.25);
  }
}

.rtz-ui-settings-box {
  position: relative;
  z-index: 4;
  .rtz-usb-face {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    cursor: pointer;
    &:has(h5) {
      padding-inline-end: 30px;
      &::after {
        content: '\e604';
        display: block;
        position: absolute;
        inset: 14px 0 auto auto;
        color: $gray60;
        pointer-events: none;
        font: normal 12px/1 navus-platform;
      }
    }
    figure {
      display: grid;
      place-items: center;
      flex: 0 0 40px;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      position: relative;
      transition: box-shadow 250ms ease, opacity 250ms ease;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        @at-root .org#{&} {
          width: 60%;
          height: 60%;
          object-fit: contain;
          border-radius: 0;
        }
      }
      + h5 {
        margin-inline-start: 10px;
      }
      @at-root .org#{&} {
        background-color: $riterzPrimary;
        &:before {
          content: '\e905';
          display: block;
          font: normal 400 22px/1 riterz-ico;
          color: #fff;
        }
        &:has(img) {
          background-color: $gray5;
          &:before {
            display: none;
          }
        }
      }
      &.rt-halo {
        outline: 2px solid $riterzPrimary;
        outline-offset: 2px;
      }
    }
    .rtz-initials {
      display: grid;
      place-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $bodyColor;
      font-size: 15px;
      text-transform: uppercase;
      text-align: center;
      color: #fff;
      letter-spacing: 0.15em;
    }
    h5 {
      font-size: 15px;
      line-height: 1.26;
      font-weight: 600;
      margin: 0;
      flex: initial;
    }
    &:hover {
      opacity: 0.7;
    }
  }
  .rtz-ui-menu:not(.quasi) {
    position: absolute;
    width: 270px;
    inset: auto 0 auto auto;
    background-color: #fff;
    border-radius: $configRadius;
    box-shadow: 0 0 8px rgba(0,0,0,0.12);
    padding: 22px 16px;
    &.on {
      opacity: 1;
      pointer-events: auto;
      transform: translate3d(0,0,0);
    }
  }
  &:not(.org) {
    .rtz-usb-face:hover {
      figure {
        opacity: 1;
        box-shadow: 0 0 14px rgba(#000,0.15);
      }
    }
  }
}

.rtz-cf-user-settings .rtz-ui-settings-box {
  .rtz-ui-menu:not(.quasi) {
    transform: translate3d(0,16px,0);
    opacity: 0;
    pointer-events: none;
    transition: $transition;
  }
  &.on:not(.quasi) {
    .rtz-ui-menu {
      opacity: 1;
      pointer-events: auto;
      transform: translate3d(0,0,0);
    }
  }
}

// Side Panel main layout
.rtz-config-side-panel {
  display: flex;
  align-items: stretch;
  align-content: stretch;
  flex-flow: column nowrap;
  justify-content: space-between;
  .rtz-config-side-panel-up, .rtz-config-side-panel-down {
    flex: initial;
  }
}
// /Side Panel main layout


.rtz-config-side-panel .rtz-ui-menu {
  padding: 20px 0 15px;
  margin-inline: -8px;
  ul li a.rtz-ico-file-manager {
    &::before {
      font-size: 16px;
    }
  }
}

.rtz-ui-global-settings {
  padding: 0;
  position: relative;
  .rtz-ui-settings-box {
    > span {
      font-family: $headingFont;
      font-size: 15px;
      font-weight: 600;
      display: block;
      padding: 4px 0 4px 35px;
      transition: $transition;
      color: $bodyColor;
      position: relative;
      &:hover {
        color: $riterzSecondary;
        &::before {
          color: $riterzSecondary;
        }
      }
      &::before {
        display: block;
        font: normal 17px/1 navus-platform;
        position: absolute;
        left: 0;
        top: 10px;
        color: $gray60;
        content: '\e92a';
      }
    }
    .rtz-ui-menu:not(.quasi) {
      inset: auto auto 90% 20px;
      transform: translate3d(0,-16px,0);
      opacity: 0;
      pointer-events: none;
      transition: $transition;
      margin: 0;
    }
    &.on {
      .rtz-ui-menu:not(.quasi) {
        opacity: 1;
        pointer-events: auto;
        transform: translate3d(0,0,0);
      }
    }
  }
}

.rtz-config-top-dash {
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
  align-items: center;
  padding: 0 0 0 21px;
  h1 {
    margin: 0 1em 0 0;
    font-size: 32px;
    font-weight: 600;
    text-wrap: balance;
  }
  @media (max-width: $phablet-screen) {
    flex-wrap: wrap;
    h1  {
      flex: 1 1 100%;
      + .rtz-organizations-dash-tools {
        margin-top: 5px;
      }
    }
  }
  @media (max-width: $phablet-screen) {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    h1 {
      margin: 0 0 0.55em;
    }
  }
}

.rtz-organizations-dash-tools {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .nv-btn-box {
    flex-wrap: nowrap;
  }
}

.rt-status-pill {
  font-size: 13px;
  line-height: 1;
  font-family: $headingFont;
  font-weight: 600;
  color: $bodyColor;
  border-radius: 8px;
  padding: 4px 8px;
  background-color: $riterzTertiary;
  text-decoration: none;
  text-transform: uppercase;
  transition: $transition;
  &:hover {
    background-color: $riterzTertiaryLight;
  }
}

.rtz-mellow {
  position: relative;
  input[type="search"], select {
    height: 40px;
    border-radius: $configRadius;
    background-color: #fff;
    border: 1px solid $gray22;
    font-size: 14px;
    padding: 0 14px;
    font-family: $bodyFont;
    color: $gray60;
    transition: $transition;
    &:focus, &:active {
      box-shadow: 0 0 2px $riterzSecondary;
      border: 1px solid $riterzSecondary;
      outline: 0;
      color: $bodyColor;
    }
  }
  &.rtz-organizations-sp-input {
    position: relative;
    input[type="search"] {
      padding-left: 40px;
    }
    &::before {
      display: block;
      font: normal 400 18px/1 navus-platform;
      content: '\e94f';
      color: $gray60;
      position: absolute;
      left: 10px;
      top: 11px;
      pointer-events: none;
    }
  }
}

.rtz-organizations-search-n-sort {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  margin: 0 0 24px;
}

.rtz-organizations-search-sort {
  position: relative;
  select {
    min-width: 120px;
    padding-right: 26px;
  }
  //&:after {
  //  content: '\e604';
  //  font: normal 400 10px/1 navus-platform;
  //  position: absolute;
  //  inset: 15px 12px auto auto;
  //  display: block;
  //}
}

.rtz-organizations-search-port {
  display: flex;
  align-items: center;
  flex: auto;
  .rtz-organizations-sp-input {
    flex: 0 0 490px;
    margin-right: 5px;
    input[type="search"] {
      width: 100%;
      color: $bodyColor;
      &::placeholder {
        color: rgba(#000,0.4);
      }
    }
  }
  .rtz-organizations-sp-filter {
    flex: auto;
    a {
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      line-height: 1.3;
      color: $gray60;
      margin-left: 20px;
      position: relative;
      transition: $transition;
      &.filter {
        padding-left: 26px;
        &::before {
          content: '\e914';
          font: normal 400 19px/1 navus-platform;
          color: $riterzSecondary;
          position: absolute;
          inset: auto 0 0 0;
        }
      }
      &:hover {
        color: $riterzSecondaryLighter;
      }
    }
  }
  @media (max-width: $tablet-screen) {
    .rtz-organizations-sp-input {
      flex: 0 0 220px;
    }
  }
}

.rtz-organizations-search-filters {
  flex: 1 1 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: row wrap;
  padding: 14px 0 0;
  gap: 10px;
  p {
    flex: initial;
    border: 1px solid #000;
    border-radius: 12px;
    background-color: #fff;
    font-size: 13px;
    line-height: 1.18;
    color: $gray60;
    position: relative;
    padding: 3px 26px 3px 10px;
    margin: 0;
    span {
      display: block;
      max-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    a {
      text-decoration: none;
      height: 22px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      position: absolute;
      inset: 0 4px auto auto;
      background-color: transparent;
      transition: $transition;
      color: $riterzSecondary;
      &:before {
        content: '\e918';
        font: normal 400 9px/1 navus-platform;
        display: block;
      }
      &:hover {
        color: $riterzSecondaryLighter;
      }
    }
  }
}

.rtz-config-tools-panel {
  position: fixed;
  inset: 0 0 0 auto;
  width: 360px;
  background-color: #fff;
  z-index: 2;
  box-shadow: 0 0 10px rgba(0,0,0,0.12);
  display: flex;
  flex-flow: column nowrap;
  transform: translate3d(100%,0,0);
  pointer-events: none;
  transition: transform 260ms ease;
  .rtz-config-tools-panel-sleeve {
    flex: auto;
  }
  .rtz-ctp-footer {
    flex: initial;
  }
  &.on {
    transform: translate3d(0,0,0);
    pointer-events: auto;
  }
}

.rtz-config-tools-panel-sleeve, .rtz-ctp-footer {
  padding: 20px 24px;
}

.rtz-ctp-footer {
  border-top: 1px solid $gray10;
}

.rtz-ctp-filter-ctrl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

a.rtz-ctp-clear {
  font-size: 15px;
  font-weight: 600;
  color: $navusQuaternary;
  font-family: $headingFont;
  transition: $transition;
  &:hover {
    color: darken($navusQuaternary,12);
  }
}

.rtz-ctp-header {
  padding: 0 0 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: 24px;
    margin: 0;
    flex: auto;
  }
}

.rtz-close-ctp {
  width: 24px;
  height: 24px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  &::before {
    content: '\e918';
    font: normal 400 14px/1 navus-platform;
    display: block;
    color: $bodyColor;
  }
  &:hover::before {
    opacity: 0.65;
  }
}

.rtz-ctp-inputs-column {
  display: block;
  margin: 0;
  .nv-form-item {
    + .nv-form-item {
      margin-top: 22px;
    }
  }
}

.rtz-ui-menu-label {
  margin-inline: -24px;
  margin-bottom: -10px;
  padding: 20px 24px;
  font-size: 15px;
  line-height: 1.1;
  border-bottom: 1px solid $gray10;
}

.rtz-side-panel-toggle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  cursor: pointer;
  color: $riterzSecondary;
  background-color: #fff;
  border: 1px solid $gray10;
  display: block;
  position: absolute;
  inset: auto 0 14% auto;
  margin-right: -14px;
  z-index: 4;
  &::before {
    content: '\e605';
    display: block;
    font: normal 600 12px/1 navus-platform;
    position: absolute;
    left: 6px;
    top: 8px;
    transform: rotate3d(0,0,0,180deg);
    transition: transform 450ms ease;
    .side-menu-collapsed & {
      transform: rotate3d(0,0,1,180deg);
      left: 8px;
    }
  }
  &:hover {
    background-color: $riterzSecondary;
    border: 1px solid $riterzSecondary;
    color: #fff;
  }
}


.rtz-link {
  font-family: $headingFont;
  text-decoration: underline;
  &.small {
    font-size: 12px;
    font-weight: 600;
  }
  &.dark {
    color: $riterzQuaternary;
    &:hover {
      color: $gray22;
    }
  }
  &.secondary {
    color: $riterzSecondary;
    &:hover {
      color: $riterzSecondaryLight;
    }
  }
  &:hover {
    text-decoration: none;
  }
}

.rtz-small-txt {
  font-size: 13px;
  line-height: 1.45;
  &.bold {
    font-weight: 700;
  }
  &.black {
    color: #000;
  }
  &.gray {
    color: $gray60;
  }
  &.red {
    color: $error;
  }
  &.align-right {
    text-align: right;
  }
  &.align-left {
    text-align: left;
  }
}


.rtz-ul-checkers {
  list-style: none;
  display: block;
  margin: 0 0 2em;
  padding: 0;
  li {
    display: block;
    position: relative;
    padding: 0 0 0 1.8em;
    &::before {
      content: '\e910';
      font: normal 400 1em/1 navus-platform;
      display: block;
      position: absolute;
      inset: 0.4em auto auto 0;
    }
    + li {
      margin-top: 0.55em;
    }
  }
}

.rtz-field-default {
  margin: 0 0 12px;
  line-height: 1.35;
  font-weight: 400;
  color: $gray60;
}

.rtz-checkbox-inline {
  .nv-regular-checkbox {
    display: inline-block;
    + span {
      display: inline-block;
    }
  }
}

.rtz-menu-toggler {
  display: none;
}

.rtz-cf-header-menu {
  flex: initial;
  display: flex;
  align-items: center;
  margin: 0;
  margin-left: auto;
  ul {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0;
    li {
      display: block;
      position: relative;
      z-index: 2;
      a {
        font-family: $headingFont;
        font-size: 14px;
        font-weight: 600;
        display: block;
        padding: 0 8px;
        text-decoration: none;
        color: $anchorColor;
        transition: $transition;
        &:hover, &.active {
          color: $riterzPrimary;
        }
      }
      ul {
        display: block;
        list-style: none;
        margin: 0;
        position: absolute;
        inset: 38px auto auto -5px;
        width: 260px;
        border-radius: $configRadius;
        background-color: #fff;
        box-shadow: 0 0 12px rgba(#000,0.15);
        padding: 12px 16px;
        opacity: 0;
        transform: translate3d(0,16px,0);
        pointer-events: none;
        transition: opacity 220ms ease, transform 220ms ease;
        li {
          display: block;
          margin: 4px 0;
          padding: 0;
          font-size: 15px;
          line-height: 1.2;
          font-family: $headingFont;
          font-weight: 600;
          a {
            display: block;
            padding: 8px 9px 7px 9px;
            border-radius: $configRadius;
            color: $anchorColor;
            transition: $transition;
            span {
              display: block;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            &:hover {
              background-color: $gray5;
              color: $riterzPrimary;
            }
          }
          &.alt {
            margin: 12px 0 4px 0;
            padding: 12px 0 0;
            border-top: 1px solid $gray22;
          }
          img {
            width: 32px;
            height: 32px;
            object-fit: cover;
            border-radius: 50%;
            flex: 0 0 32px;
            margin: 0 8px 0 0;
            display: block;
            line-height: 1;
          }
          &:has(img) {
            a {
              display: flex;
              align-items: center;
              justify-content: flex-start;
            }
          }
        }
      }
      &:has(ul) {
        > a {
          position: relative;
          &:after {
            display: block;
            position: absolute;
            right: -0.8em;
            top: 0.8em;
            content: '\e604';
            font: normal 400 0.7em/1 navus-platform;
          }
        }
      }
      &:hover {
        ul {
          opacity: 1;
          transform: translate3d(0,0,0);
          pointer-events: auto;
        }
      }
    }
  }
  @media (max-width: 980px) {
    background-color: #fff;
    position: fixed;
    z-index: 10;
    inset: 70px 0 0;
    display: block;
    transform: translate3d(-100%,0,0);
    opacity: 0;
    pointer-events: none;
    transition: all 300ms ease;
    ul {
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: stretch;
      margin: 0;
      padding: 0;
      li {
        font-size: 21px;
        padding: 0;
        margin: 0;
        flex: 1 1 100%;
        a {
          padding: 16px 24px 16px 37px;
          margin: 0;
        }
        .sub-toggler {
          position: absolute;
          inset: 16px 20px auto auto;
          cursor: pointer;
          z-index: 2;
          width: 26px;
          height: 26px;
          border-radius: 50%;
          display: grid;
          place-items: center;
          background-color: #fff;
          color: $riterzSecondary;
          font: normal 700 13px/1 navus-platform;
          &:before {
            content: '\e604';
            display: block;
          }
          &.on {
            &:before {
              content: '\e607';
            }
            + ul {
              display: block;
            }
          }
        }
        ul {
          border: none;
          position: relative;
          inset: auto;
          display: none;
          overflow: hidden;
          //grid-template-rows: 0fr;
          //grid-template-columns: 100%;
          transform: none;
          width: auto;
          padding: 0;
          margin: 0;
          border-radius: 0;
          background-color: $gray5;
          box-shadow: none;
          opacity: 1;
          pointer-events: auto;
          li, li.alt {
            padding: 0;
            margin: 0;
            border-width: 1px 0 0;
            border-color: rgba(0,0,0,0.1);
            a {
              padding: 16px 24px 16px 37px;
            }
          }
          &.on {
            //grid-template-rows: 1fr;
            display: block;
          }
        }
        + li {
          border-top: 1px solid rgba(#000,0.1);
        }
      }
    }
  }
}

.rtz-cf-header.show-navigation {
  .rtz-cf-header-menu {
    transform: translate3d(0,0,0);
    opacity: 1;
    pointer-events: auto;
  }
  .rtz-menu-toggler {
    background-color: $riterzSecondary;
    &:before {
      content: '\e918';
      color: #fff;
    }
  }
}

@media (max-width:980px) {
  .rtz-menu-toggler {
    width: 40px;
    height: 40px;
    border-radius: $configRadius;
    background-color: #fff;
    border: 1px solid rgba(#000,0.1);
    display: grid;
    place-items: center;
    cursor: pointer;
    transition: $transition;
    margin-right: 20px;
    margin-left: auto;
    &:before {
      content: '\e94e';
      font: normal 400 19px/1 navus-platform;
      color: $riterzSecondary;
    }
    &:hover {
      border-color: $riterzSecondary;
      background-color: $riterzSecondary;
      &:before {
        color: #fff;
      }
    }
  }
}

.rt-dash-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: 0 0 24px;
  gap: 0 12px;
  > h1, > h2, >h3 {
    flex: auto;
    margin: 0;
  }
  .rtz-dash-header-tools {
    flex: initial;
  }
  @media (max-width: 800px) {
    flex-flow: column nowrap;
    align-items: flex-start;
    gap: 18px;
  }
}

.rt-split {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.rt-panel {
  position: relative;
  padding: 24px;
  border-radius: 16px;
  background: #FFF;
  overflow: hidden;

  &.spaced-top-24 {
    margin-top: 24px;
  }
  &.spaced-bottom-24 {
    margin-top: 24px;
  }
}

.rt-table {
  width: 100%;
  border-radius: 8px;
  // border: 1px solid var(--Gray-10, #E3E3E3);

  tr {
    color: var(--Secondary-Default, #200B39);
  }

  th {
    padding: 12px;
    text-align: left;
    text-transform: uppercase;
    border-bottom: 1px solid var(--Gray-10, #E3E3E3);
    color: var(--Secondary-Default, #200B39);
    font-size: 12px;
    font-weight: 400;
  }

  td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid var(--Gray-10, #E3E3E3);
    color: var(--Secondary-Default, #200B39);
    font-size: 14px;
  }

  tr:last-child td {
    border-bottom: none;
  }
}

.rt-clickable {
  cursor: pointer;
}

nav.rt-nav {
  width: 120px;
  color: var(--Secondary-Default, #200B39);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      a {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px 16px;
        border-radius: 4px;
        color: var(--Secondary-Default, #200B39);
        text-decoration: none;
        transition: all 300ms ease;

        &:hover {
          background: var(--Gray-5, #F1F1F1);
        }

        &.active {
          background: var(--Gray-5, #F1F1F1);
        }
      }
    }
  }
}