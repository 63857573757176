.p-button.p-button-rounded {
  padding: 9px 15px;

  .p-button-label {
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 14px */
  }
}