@keyframes fadein {
    0% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.animate-fadein {
    animation: fadein .5s linear
}

@keyframes fadeout {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

.animate-fadeout {
    animation: fadeout .5s linear
}