.rt-breadcrumbs {
  color: var(--Secondary-Body-Text, #5E5568);
  font-size: 14px;
  padding: 0 8px;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      a {
        color: var(--Primary-Default, #F15AB5);
        text-decoration: none;
      }

      + li {
        margin-left: 10px;
        &:before {
          content: '/';
          margin-right: 10px;
        }
      }
    }
  }
}

.rt-profile-header {
  display: flex;
  gap: 16px;
  margin: 20px 0;

  .rt-profile-header-image {
    flex: 0 0 80px;
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }

  .rt-profile-header-info {
    flex: 1 1 auto;

    .rt-profile-header-info-top {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;

      h1 {
        margin-bottom: 0;
        color: var(--Secondary-Default, #200B39);
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
      }
  
      .rt-profile-unverified {
        display: inline-flex;
        padding: 4px 10px 4px 8px;
        align-items: center;
        gap: 4px;
        border-radius: 16px;
        border: 1px solid var(--Secondary-Body-Text, #5E5568);
        color: var(--Secondary-Body-Text, #5E5568);
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 12px */
      }
  
      .rt-profile-verified {
        display: inline-flex;
        padding: 4px 10px 4px 8px;
        align-items: center;
        gap: 4px;
        border-radius: 16px;
        border: 1px solid var(--Secondary-Default, #200B39);
        background-color: #FFF;
        color: var(--Secondary-Default, #200B39);
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 12px */
      }

      a {
        color: var(--Secondary-Body-Text, #5E5568);
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 125% */
        text-decoration-line: underline;
        cursor: pointer;
      }
    }


    .rt-profile-info {
      color: var(--Text-Body-Text, #5E5568);
      font-size: 16px;
      line-height: 28px;
    }

    .rt-profile-last-updated {
      color: var(--Text-Body-Text, #5E5568);
      font-size: 16px;
      font-style: italic;
      line-height: 28px;
    }
  }

  .rt-profile-header-actions {
    flex: 0 0 80px;
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }
}

.rt-sub-navigation {
  display: flex;
  justify-content: space-between;
  border-radius: 36px;
  background: var(--Gray-0, #FFF);
  margin-bottom: 24px;

  ul {
    display: flex;
    margin: 0;
    padding: 12px 20px 12px 12px;
    align-items: center;
    gap: 32px;
    align-self: stretch;
    list-style: none;

    li {
      a {
        display: flex;
        padding: 12px 0;
        margin-left: 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 19px;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 14px */
        color: var(--Text-Body-Text, #5E5568);
        text-decoration: none;
        cursor: pointer;

        &.active {
          padding: 12px 24px;
          color: var(--Gray-0, #FFF);
          background: var(--Secondary-Default, #200B39);
        }
      }

      .lightning {
        margin-top: 1px;
        margin-left: -4px;
        border-radius: 9px;
        border: 0.5px solid var(--Alert-Warning, #FFA400);
        background: rgba(255, 209, 128, 0.25);
      }
    }

    li:first-child {
      a.active {
        margin-left: 0;
      }
    }
  }
}

.rt-sub-sub-navigation {

  ul {
    display: flex;
    margin: 0;
    padding: 0 0 0 12px;
    align-items: center;
    align-self: stretch;
    list-style: none;

    li {
      a {
        position: relative;
        display: flex;
        padding: 8px 20px;
        justify-content: center;
        align-items: center;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 14px */
        color: var(--Text-Body-Text, #5E5568);
        text-decoration: none;
        cursor: pointer;

        &.active {
          color: var(--Secondary-Default, #200B39);

          &::after {
            content: '';
            position: absolute;
            display: block;
            bottom: -2px;
            width: 100%;
            height: 2px;
            background: var(--Secondary-Default, #200B39);
          }
        }
      }

      .lightning {
        margin-top: 1px;
        margin-left: -4px;
        border-radius: 9px;
        border: 0.5px solid var(--Alert-Warning, #FFA400);
        background: rgba(255, 209, 128, 0.25);
      }
    }
  }
}

.rt-container {
  max-width: 1440px;
  margin: auto;
}

.rt-panel {
  width: 100%;

  .rt-panel-title {    
    display: inline-flex;
    align-items: center;
    gap: 8px;

    color: var(--Secondary-Default, #200B39);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 125% */
  }

  p {
    overflow: hidden;
    color: var(--Text-Body-Text, #5E5568);
    font-feature-settings: 'liga' off, 'clig' off;
    text-overflow: ellipsis;

    /* Paragraph */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
  }

  .rt-view-all {
    color: var(--Text-Body-Text, #5E5568);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
    text-decoration-line: underline;
    cursor: pointer;
  }

  .sponsorship-icon {
    display: inline-block;
    width: 28px;
    height: 28px;
    border-radius: 14px;
    border: 0.583px solid var(--Alert-Warning, #FFA400);
    background: rgba(255, 209, 128, 0.25);

    svg {
      margin: -1px;
    }
  }

  &.topics {
    background: var(--Secondary-Default, #200B39);
    background-image: url(../../public/assets/topics-background.svg);
    background-repeat: no-repeat;
    background-position: right -74px center;
    color: var(--Gray-0, #FFF);
    
    .rt-panel-title, .rt-view-all, p {
      color: var(--Gray-0, #FFF);
    }
    .rt-pills {    
      .rt-pill {
        background: #FCDEF0;overflow: hidden;
        color: var(--Gray-0, #FFF);
        background: rgba(252, 222, 240, 0.25);
        backdrop-filter: blur(4px);
      }
    }
  }

  &.topics-alt {
    background: var(--Quaternary-Default, #CB55B4);
    background-image: url(../../public/assets/topics-background.svg);
    background-repeat: no-repeat;
    background-position: right -74px center;
    color: var(--Gray-0, #FFF);
    
    .rt-panel-title, .rt-view-all, p {
      color: var(--Gray-0, #FFF);
    }
    .rt-pills {    
      .rt-pill {
        background: #FCDEF0;overflow: hidden;
        color: var(--Gray-0, #FFF);
        background: rgba(252, 222, 240, 0.25);
        backdrop-filter: blur(4px);
      }
    }
  }

  &.sponsor {
    position: relative;
    background: var(--Secondary-Default, #200B39);
    color: var(--Gray-0, #FFF);
    padding: 40px;
    text-align: center;

    &::before {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.6;
      background-image: url(../../public/assets/sports-audience.webp);
      background-repeat: no-repeat;
      background-position: 0px -184.39px;
      background-size: 100% 431.205%;
    }
    
    h2, p {
      position: relative;
      color: var(--Gray-0, #FFF);
    }

    h2 {
      margin-bottom: 4px;
      font-family: Montserrat;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px; /* 125% */
    }
  }
}

.rt-metrics {
  display: flex;
  justify-content: space-between;

  .rt-metric {
    .rt-direction {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 24px;
      border-radius: 15px;
      &.up {
        background: rgba(156, 227, 184, 0.30);
      }
      &.down {
        background: rgba(255, 135, 128, 0.30);
      }
      svg {
        display: block;
      }
    }
    .rt-metric-value {
      color: var(--Secondary-Default, #200B39);
      text-align: right;
      font-family: Montserrat;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 42px;
    }
    .rt-metric-label {
      color: var(--Text-Body-Text, #5E5568);
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px; /* 100% */
      text-transform: capitalize;
    }
  }
}

.rt-pills {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  max-height: 66px;
  overflow: hidden;    
  margin: 0 0 1.275em;

  &.all {
    max-height: none;
  }
  &.one-row {
    max-height: 33px;
  }
  &.two-row {
    max-height: 66px;
  }
  &.six-row {
    max-height: 198px;
  }

  .rt-pill {
    padding: 4px 12px;
    border-radius: 14px;
    background: #FCDEF0;overflow: hidden;
    color: var(--Secondary-Default, #200B39);
    text-align: center;
    text-overflow: ellipsis;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
  }
}

.rt-pill-2 {
  height: 22px;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 8px;
  background: var(--Secondary-Lighter, #D3CED8);
  color: #200B39;
  text-align: center;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 15.6px */
  text-transform: uppercase;

  &.active {
    background: var(--Primary-Default, #F15AB5);
    color: var(--Gray-0, #FFF);
  }
}

.rt-social-posts {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  .rt-social-post {
    position: relative;
    width: 203px;

    &::after {
      content: '';
      position: absolute;
      left: 8px;
      top: 8px;
      width: 28px;
      height: 28px;
      padding: 8px;
      background: #FFF;
      border-radius: 50%;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-position: center;
    }
    &.facebook::after {
      background-image: url(../../public/assets/facebook.svg);
    }
    &.instagram::after {
      background-image: url(../../public/assets/instagram.svg);
    }
    &.linkedin::after {
      background-image: url(../../public/assets/linkedin.svg);
    }

    &:hover {
      transition: all 300ms ease;

      figure::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.6);
        background-size: 40px 40px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16' fill='none'%3E%3Cpath d='M4.20907 13.6666C3.8723 13.6666 3.58724 13.55 3.35391 13.3166C3.12057 13.0833 3.00391 12.7983 3.00391 12.4615V3.53848C3.00391 3.2017 3.12057 2.91665 3.35391 2.68331C3.58724 2.44998 3.8723 2.33331 4.20907 2.33331H8.41407V3.33331H4.20907C4.15774 3.33331 4.11074 3.3547 4.06807 3.39748C4.0253 3.44015 4.00391 3.48715 4.00391 3.53848V12.4615C4.00391 12.5128 4.0253 12.5598 4.06807 12.6025C4.11074 12.6453 4.15774 12.6666 4.20907 12.6666H13.1321C13.1834 12.6666 13.2304 12.6453 13.2731 12.6025C13.3159 12.5598 13.3372 12.5128 13.3372 12.4615V8.25648H14.3372V12.4615C14.3372 12.7983 14.2206 13.0833 13.9872 13.3166C13.7539 13.55 13.4689 13.6666 13.1321 13.6666H4.20907ZM7.15007 10.223L6.44757 9.52048L12.6347 3.33331H10.0039V2.33331H14.3372V6.66665H13.3372V4.03581L7.15007 10.223Z' fill='%23200B39'/%3E%3C/svg%3E");
      }
    }

    figure {
      margin: 0;
      width: 203px;
      height: 208px;
      background: var(--Secondary-Lighter, #D3CED8);
      border-radius: 16px;
      position: relative;

      img {
        width: 203px;
        height: 208px;
        border-radius: 16px;
        object-fit: cover;
      }       
    }

    p {
      margin: 16px 0;
      height: 48px;
      overflow: hidden;
      color: var(--Text-Body-Text, #5E5568);
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
    }

    .rt-social-post-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .rt-social-post-likes {
      display: inline-flex;
      align-items: center;
      gap: 8px;
      color: var(--Text-Body-Text, #5E5568);
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
    }

    .rt-social-post-timestamp {
      color: var(--Secondary-Light, #8F859C);
      font-size: 12px;
    }
  }
}

.rt-sponsorship-channels {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  .rt-sponsorship-channel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 20px 16px;
    border-radius: 12px;
    border: 0.85px solid var(--Gray-22, #C6C6C6);
    background: var(--Gray-0, #FFF);

    color: var(--Secondary-Default, #200B39);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 125% */

    svg, img {
      width: 40px;
    }

    &.see-more {
      background: var(--Gray-5, #F1F1F1);
    }
  }
}

.rt-profile-events {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  padding: 24px 0;

  .rt-profile-event {
    padding: 24px;
    border-radius: 16px;
    border: 1px solid var(--Gray-22, #C6C6C6);

    .rt-profile-event-header {
      display: flex;
      align-items: center;
      gap: 16px;

      img {
        width: 40px;
        height: 40px;
        object-fit: contain;
        border-radius: 50%;
      }

      h3 {
        overflow: hidden;
        color: #200B39;
        text-overflow: ellipsis;
        margin: 0;

        /* H5 */
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
      }
    }

    .rt-profile-event-body {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
      
      color: #200B39;
      font-family: Montserrat;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      
      .togo {
        display: inline-block;
        padding: 4px 8px;
        border-radius: 8px;
        background: var(--Gray-5, #F1F1F1);
        color: var(--Gray-60, #666);
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px; /* 100% */
      }
    }

  }
}

.rt-report-channel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  svg {
    flex-shrink: 0;
  }

  .logo {
    margin-bottom: 24px;
  }

  .link {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 48px;

    a {
      color: var(--Secondary-Default, #200B39);
      font-feature-settings: 'liga' off, 'clig' off;
      /* H4 */
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 133.333% */
      text-decoration-line: underline;      
    }
  }

  .followers {
    display: inline-flex;
    align-items: center;
    gap: 8px;

    color: var(--Text-Body-Text, #5E5568);
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 160% */
  }
}

.rt-sponsorship-offers {
  display: flex;
  gap: 16px;

  .rt-sponsorship-offer {
    flex: 1 1 auto;
    display: flex;
    gap: 16px;
    align-items: center;
    padding: 24px 20px 0;
    border-radius: 16px;
    background: var(--Gray-5, #F1F1F1);
    height: 300px;

    .rt-sponsorship-offer-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1 1 auto;
      height: 100%;
      padding-bottom: 24px;

      .rt-sponsorship-offer-channel {
        img {
          width: 32px;
          height: 32px;
          object-fit: contain;
        }
      }

      .rt-sponsorship-offer-description {
        h3 {
          overflow: hidden;
          color: var(--Secondary-Default, #200B39);
          text-overflow: ellipsis;
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; /* 125% */
        }

        p {
          color: var(--Text-Body-Text, #5E5568);
          font-family: Montserrat;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }
      }
    }

    .rt-sponsorship-offer-image {
      flex: 1 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      img {
        max-height: 100%;
        max-width: 100%;
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}

.rt-sponsorship-packages {
  display: flex;
  gap: 16px;

  .rt-sponsorship-package {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 24px 20px;
    border-radius: 24px;
    border: 1px solid var(--Gray-22, #C6C6C6);
    height: 300px;

    .rt-sponsorship-package-label {
      align-self: flex-end;
      display: inline-flex;
      padding: 4px 8px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: var(--Primary-Default, #F15AB5);
      color: var(--Gray-0, #FFF);
      text-align: center;
      font-family: Montserrat;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 15.6px */
      text-transform: uppercase;
    }

    .rt-sponsorship-package-description {
      align-self: flex-start;

      .rt-sponsorship-package-channels {
        display: flex;
        gap: 8px;
        padding-bottom: 8px;
      }

      p {
        color: var(--Text-Body-Text, #5E5568);
        font-family: Montserrat;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 133.333% */
      }
    }

    .rt-sponsorship-package-action {
      align-self: flex-start;
    }
  }
}

.rt-sponsorship-channels1 {
  display: flex;
  gap: 16px;

  .rt-sponsorship-channel1 {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 24px 20px;
    border-radius: 24px;
    border: 1px solid var(--Gray-22, #C6C6C6);
    height: 300px;

    .rt-sponsorship-channel1-label {
      align-self: flex-end;
      display: inline-flex;
      padding: 4px 8px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: var(--Gray-5, #F1F1F1);
      color: var(--Secondary-Body-Text, #5E5568);
      text-align: center;
      font-family: Montserrat;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 15.6px */
    }

    .rt-sponsorship-channel1-description {
      align-self: center;
      text-align: center;

      h3 {
        margin-top: 4px;
        color: var(--Secondary-Default, #200B39);
        text-align: center;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
      }

      p {
        color: var(--Text-Body-Text, #5E5568);
        font-family: Montserrat;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 133.333% */
      }

      span {
        display: inline-flex;
        gap: 8px;
        color: var(--Secondary-Light, #8F859C);
        font-family: Montserrat;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 160% */
      }
    }

    .rt-sponsorship-channel1-action {
      align-self: flex-start;
    }
  }
}

.rt-sponsorship-brochures {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  .rt-sponsorship-brochure {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 24px 20px;
    border-radius: 24px;
    background: var(--Gray-5, #F1F1F1);
    height: 300px;

    svg {
      margin: 40px;
    }

    h3 {
      overflow: hidden;
      color: var(--Secondary-Default, #200B39);
      text-align: center;
      text-overflow: ellipsis;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 125% */
    }
  }
}

.rt-pv-offer {
  position: relative;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid var(--Gray-22, #C6C6C6);
  background: var(--Base-White, #FFF);

  .rt-pv-offer-group {
    position: absolute;
    top: 48px;
    left: 48px;
    display: inline-flex;
    padding: 8px 16px;
    color: var(--Secondary-Body-Text, #5E5568);
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    border-radius: 14px;
    background: #FFF;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
  }

  figure {
    margin: 0 0 24px;
    border-radius: 16px;
    overflow: hidden;

    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  }

  h2 {
    margin: 0 0 8px;
    color: var(--Secondary-Default, #200B39);
    font-feature-settings: 'liga' off, 'clig' off;

    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  .rt-pv-offer-dates {
    color: var(--Secondary-Body-Text, #5E5568);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-transform: capitalize;
  }

  h3 {
    margin: 24px 0;
    color: var(--Secondary-Default, #200B39);
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }

}