.rt-config {
  display: flex;
  margin: -24px;
  width: calc(100% + 48px);
  height: calc(100% + 48px);
  background-color: #fff;

  .rt-config-side {
    flex: 0 0 260px;
    min-width: 0;
    padding-inline: 20px;
    position: relative;
    overflow: auto;

    .rt-config-organization {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 16px;
      border-radius: 8px;
      color: var(--Secondary-Default, #200B39);
      font-family: Montserrat;
      font-size: 13px;
      font-weight: 600;
      cursor: pointer;
    }

    nav {
      padding-top: 16px;
      color: var(--Secondary-Default, #200B39);
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          margin-bottom: 8px;

          a {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 8px 16px;
            border-radius: 24px;
            color: var(--Secondary-Default, #200B39);
            text-decoration: none;
            transition: all 300ms ease;

            &:hover {
              background: var(--Gray-5, #F1F1F1);
            }

            &.active {
              background: var(--Gray-5, #F1F1F1);
            }
          }
        }
      }
    }
  }

  .rt-config-main {
    flex: auto;
    padding: 24px;
    background-color: #F1F1F1;
    border-radius: 16px;
    overflow: auto;
  }
}