h1, h2, h3, h4, h5, h6 {
  color: $riterzSecondary;
  font-family: $headingFont;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0.94em;
  line-height: 1.28;
  &.semi-light {
    font-weight: 600;
  }
  &.light {
    font-weight: 400;
  }
  &.medium {
    font-weight: 500;
  }
  + .nv-subtitle {
    margin-top: - 0.8em;
    font-weight: 600;
    &.light {
      font-weight: 400;
    }
  }
  &.pale {
    opacity: 0.65;
  }
  &.spaceless {
    margin: 0;
  }
}

// Config typography

h1 {
  font-size: 32px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

// /Config typography

h1, h2, h3, h4, h5, h6, .web {
  &.h1 {
    font-size: 46px;
    line-height: 1.22;
    @media (max-width: $tablet-screen) {
      font-size: 38px;
    }
    @media (max-width: $phablet-screen) {
      font-size: 32px;
    }
  }
}

h1, h2, h3, h4, h5, h6, .web {
  &.h2 {
    font-size: 34px;
    line-height: 1.27;
    @media (max-width: $tablet-screen) {
      font-size: 32px;
    }
    @media (max-width: $phablet-screen) {
      font-size: 27px;
    }
  }

}

h1, h2, h3, h4, h5, h6, .web {
  &.h3 {
    font-size: 28px;
    line-height: 1.3;
    @media (max-width: $phablet-screen) {
      font-size: 22px;
    }
  }
}

h1, h2, h3, h4, h5, h6, .web {
  &.h4 {
    font-size: 22px;
    line-height: 1.35;
    @media (max-width: $phablet-screen) {
      font-size: 18px;
    }
  }
}

h1, h2, h3, h4, h5, h6, .web {
  &.h5 {
    font-size: 18px;
    line-height: 1.4;
    @media (max-width: $phablet-screen) {
      font-size: 16px;
    }
  }
}

h1, h2, h3, h4, h5, h6, .web {
  &.h6 {
    font-size: 16px;
    line-height: 1.45;
  }
}

ul, ol, p {
  margin: 0 0 1.275em;
  &.spaceless {
    margin: 0;
  }
  &.semi-light {
    font-weight: 500;
  }
  &.light {
    font-weight: 400;
  }
  &.medium {
    font-weight: 600;
  }
}

/* Navus Header */

.nv-header {
  &.center {
    text-align: center;
  }
}




/* Blockquote */

blockquote {
  display: block;
  padding: 24px 30px 5px 48px;
  font-style: italic;
  position: relative;
  border: none;
  border-left: 5px solid $gray22;
  margin: 0 0 1.6em;
  background-color: rgba(0,0,0,0.015);
  &:before {
    content: '“';
    position: absolute;
    font-size: 54px;
    font-family: Tahoma, Arial, SansSerif;
    line-height: 1;
    left: 8px;
    top: 8px;
    color: $gray22;
  }
}

.nv-column-content > ul:not([class]) {
  list-style: none;
  display: block;
  padding: 0;
  li {
    display: block;
    padding: 0 0 0 16px;
    line-height: 1.35;
    + li {
      margin: 10px 0 0;
    }
    &:before {
      content: ' ';
      width: 6px;
      height: 6px;
      background-color: $navusQuaternary;
      border-radius: 50%;
      display: block;
      float: left;
      margin: 8px 0 0 -16px;
      .nv-navus-offering & {
        background-color: $navusSecondary;
      }
    }
  }
}

.nv-column-content > ol:not([class]) {
  display: block;
  padding: 0 0 0 15px;
  li {
    line-height: 1.35;
    + li {
      margin: 10px 0 0;
    }
  }
}

.nv-underlined-heading {
  h1,h2,h3,h4,h5,h6 {
    position: relative;
    border-bottom: 4px solid rgba(0,0,0,0.1);
    span {
      display: inline-block;
      padding-bottom: 0.675em;
      position: relative;
      &:after {
        display: block;
        content: ' ';
        position: absolute;
        bottom: -4px;
        left: 0;
        right: 0;
        height: 4px;
        background-color: rgba(0,0,0,0.1);
      }
    }
  }
}


.grey60 {
  color: $gray60;
}

.grey22 {
  color: $gray22;
}

.grey80 {
  color: $gray80;
}

.grey10 {
  color: $gray10;
}
