.rt-empty-state {
  margin: 16px auto;
  text-align: center;

  &.large {
    margin: 50px auto;

    img {
      width: 250px;
    }
  }

  &.medium {
    margin: 16px auto;

    img {
      width: 128px;
    }
  }
}