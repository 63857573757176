/* Global */
$bodyFontSize: 16px;

/* Font families */
$headingFont: Montserrat, Helvetica, Arial, sans-serif;
$bodyFont: 'PT Sans', Helvetica, Arial, sans-serif;

$bodyColorDarkTheme: #ccc;
$grayDark: #fff;


/* Colors */
$bodyColor: #5E5568;
$anchorColor: #200B39;
$anchorColorHover: #C6C6C6;

/* Navus Color Set */
$navusPrimary: #CB55B4;
$navusSecondary: #F15AB5;
$navusTertiary: #200B39;
$navusQuaternary: #CB55B4;
$navusQuinary: #eee;
$navusAnchorColor: #CB55B4;
$navusRed: #ee5c62;
$riterzRed: #FB3640;


/* Front Web Colors */
// Search
$accentColorWeb1: #F15AB5;
$accentColorWeb2: #CB55B4;
$grayishBg: #f4f5f8;
$neutralGray: #7c8a9c;
// Global
$purchase: #fb671d;
/* /Front Web Colors */


/* Riterz Color Set */
$riterzPrimary: #F15AB5;
$riterzPrimaryDark: #D41287;
$riterzPrimaryLight: #F7A1D5;
$riterzSecondary: #200B39;
$riterzSecondaryLight: #8F859C;
$riterzSecondaryLighter: #D3CED8;
$riterzQuaternary: #CB55B4;
$riterzQuaternaryDark: #892A76;
$riterzQuaternaryLight: #DB8ACB;
$riterzTertiary: #43E5FC;
$riterzTertiaryDark: #04BED8;
$riterzTertiaryLight: #9BF1FD;


/* Riterz Grays */
$gray80: #333;
$gray60: #666;
$gray22: #C6C6C6;
$gray10: #E3E3E3;
$gray5: #F1F1F1;

/* Messages colors */
$error: #ff0000;
$errorLight: #FF8780;
$success: #4BCC7E;
$successLight: #9CE3B8;
$warn: #FFA400;
$warnLight: #FFD180;


/* Special case colors */
$paleRed: #e44040;

/* Config BG gray */
$paleGray: #9e9e9e;


/* Light Skin */
$lightSkinAlt: #f9f9f9;

/* Dark Skin */
$darkHeader: #323232;
$darkTheme1: #2f2f2f;
$darkTheme2: #3a3a3a;
$darkThemeWhite: #444;

/* Live Stage Colors */
$liveStage1: #ed5cc6;
$liveStage2: #ffdb64;
$liveStage3: #62e9b2;
$liveStage4: #8d62e9;

/* Front conf */
/* SearchPage */

/* Header */
$headerHeight: 80px;
$headerMobileHeight: 70px;
$headerSearchWidth: 520px;

/* Global defo Transitions */
$transition: all 200ms ease;

/* Materials subMenu */
$subMenuHeight: 50px;

/* Spacings */
$small-spaced-gap: 0.5em;
$semi-spaced-gap: 1em;
$spaced-gap: 2em;
$large-spaced-gap: 4em;
$extra-large-spaced-gap: 6.5em;
$mega-spaced-gap: 8em;
$giant-spaced-gap: 10em;

/* Grid */
$column-gap: 34px;
$gutter-width: 1280px;
$small-spaced-gap: 0.5em;
$semi-spaced-gap: 1em;
$spaced-gap: 2em;
$large-spaced-gap: 4em;
$extra-large-spaced-gap: 6em;
$gap-values: 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 24, 28, 30, 40, 50, 60, 70, 80;

/* Grid ranges */
$laptop-screen: 1441px;
$tablet-screen: 992px;
$phablet-screen: 720px;
$phone-screen: 460px;

/* Miscs */
$border-radius: 4px;
$column-box-padding: 26px;

/* Border radius */
$radius-values: 2, 3, 4, 5, 6, 8, 10, 12, 16, 20, 25, 30, 50, 80, 100, 120;

/* Extends */

.stick {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}


.center-flex {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.center-flex-inline {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.sticky-flex {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  height: 100%;
}

.clear {
  display: table;
  width: 100%;
  clear: both;
  content: ' ';
}
/* /Extends */



/* Cubic Beziers */
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);

/* Module specific variables (from Config) */
$platePadding: 24px;
$configRadius: 4px;
$configRadiusHigh: 16px;
$plateShadow: 0 0 5px rgba(0,0,0,0.12);
