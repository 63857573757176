/* You can add global styles to this file, and also import other style files */
// @import "primeng/resources/themes/lara-light-blue/theme.css";
// @import "primeng/resources/primeng.css";

@import "primeicons/primeicons.css";

@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0&");

@import "./style/variables";
@import "./style/buttons";
@import "./style/typography";
@import "./style/grid";
@import "./style/layout";
@import "./style/loading";
@import "./style/empty_state";
@import "./style/animations";

@import "./style/marketplace_search";
@import "./style/marketplace_profile";
@import "./style/config";
@import "./style/auth";

body, html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: "PT Sans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p-dynamicdialog {
  .p-dialog-header {
    color: var(--Secondary-Default, #200B39);
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }

  .p-dialog-content {
    color: var(--Text-Body-Text, #5E5568);
    font-size: 14px;
    font-weight: 400;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
}

// Fix for inputnumber width
p-inputnumber, .p-inputnumber, .p-inputnumber-input {
  max-width: 100%;
  width: 100%;
}

p-checkbox {
  .p-checkbox {
    vertical-align: middle;
  }
}

p-autoComplete {
  .p-autocomplete-multiple-container {
    width: 100%;
  }
}

.p-tabpanels {
  padding: 20px 0 0 !important;
}


.field {
  padding-bottom: 8px;

  label {
    display: inline-block;
    margin-bottom: 4px;
  }

  .p-error {
    margin: 6px;
  }
}

.p-selectbutton .p-button.p-highlight {
  color: #4b5563;
  background: var(--Gray-5, #F1F1F1);
  border-color: #d1d5db;
}