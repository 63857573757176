/* Grid ranges */
/* moved to 'variables' */

@use "sass:math";

$border-radius: 4px;

/* Extends */
.stick {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.clear {
  display: table;
  width: 100%;
  clear: both;
  content: ' ';
}
/* /Extends */

.rt-section {
  clear: both;
  &[style*="background-image"] {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  &.relative {
    position: relative;
  }
  &.video {
    position: relative;
    .rt-port {
      position: relative;
      z-index: 2;
    }
    .rt-video {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      overflow: hidden;
      .rt-video-port {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        padding-top: 25px;
        height: 0;
        video, iframe, vg-player {
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          top: 50%;
          bottom: 0;
          width: 100% !important;
          height: 100% !important;
          transform: translate3d(0,-50%,0);
        }
      }
    }
  }
}

.rt-section {
  &[class*="-overlay"] {
    position: relative;
    &:before {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
  &.light-overlay:before {
    background-color: rgba(255,255,255,0.55);
  }
  &.dark-overlay:before {
    background-color: rgba(0,0,0,0.7);
  }
  &.grayish {
    background-color: $grayishBg;
  }
}


.rt-port {
  overflow: visible;
  margin: 0 auto;
  .guttered > & {
    width: $gutter-width;
    @media (max-width: ($gutter-width + ($column-gap * 2))) {
      width: auto;
      margin: 0 $column-gap * 0.5;
      flex: auto;
    }
    @media (max-width: $phablet-screen) {
      margin: 0 $column-gap * 0.5;
    }
  }
  //.rt-section:not(.guttered) & {
  //  overflow-x: hidden;
  //}
  .full-screen & {
    min-height: 100vh;
    @extend .center-flex;
    > .rt-row {
      flex: auto;
    }
  }
  .dark-overlay &, .light-overlay & {
    position: relative;
    z-index: 2;
  }
  /* From 'old' grid to keep previous spacings */
  &.small-spaced {
    padding-top: $small-spaced-gap;
    padding-bottom: $small-spaced-gap;
  }
  &.small-spaced-bottom {
    padding-bottom: $small-spaced-gap;
  }
  &.small-spaced-top {
    padding-top: $small-spaced-gap;
  }
  &.semi-spaced {
    padding-top: $semi-spaced-gap;
    padding-bottom: $semi-spaced-gap;
  }
  &.semi-spaced-bottom {
    padding-bottom: $semi-spaced-gap;
  }
  &.semi-spaced-top {
    padding-top: $semi-spaced-gap;
  }
  &.spaced {
    padding-top: $spaced-gap;
    padding-bottom: $spaced-gap;
  }
  &.spaced-bottom {
    padding-bottom: $spaced-gap;
  }
  &.spaced-top {
    padding-top: $spaced-gap;
  }
  &.large-spaced {
    padding-top: $large-spaced-gap;
    padding-bottom: $large-spaced-gap;
  }
  &.large-spaced-bottom {
    padding-bottom: $large-spaced-gap;
  }
  &.large-spaced-top {
    padding-top: $large-spaced-gap;
  }
  &.extra-large-spaced {
    padding-top: $extra-large-spaced-gap;
    padding-bottom: $extra-large-spaced-gap;
  }
  &.extra-large-spaced-top {
    padding-top: $extra-large-spaced-gap;
  }
  &.extra-large-spaced-bottom {
    padding-bottom: $extra-large-spaced-gap;
  }
  /* from old grid */
}

.rt-row {
  clear: both;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  @each $row-gap in $gap-values {
    @at-root .gap-#{$row-gap}#{&} {
      /*padding-left: ($row-gap / 2) + px;
      padding-right: ($row-gap / 2) + px;*/
      margin-left: (-$row-gap * 0.5) + px;
      margin-right: (-$row-gap * 0.5) + px;
    }
    &.no-side-gap.gap-#{$row-gap}#{&} {
      margin-left: -($row-gap) + px;
      margin-right: -($row-gap) + px;
    }
  }
  &.flow-center {
    justify-content: center;
  }
  &.flow-right {
    justify-content: flex-end;
  }
  &.v-stretch {
    align-items: stretch;
    align-content: stretch;
  }
  &.space-between {
    justify-content: space-between;
    flex-flow: row wrap;
  }
  &.guttered {
    margin: 0 (-$column-gap * 0.5);
  }
  /* Custom spaces */
  @each $row-gap in $gap-values {
    @at-root .spaced-top-#{$row-gap}#{&} {
      padding-top: $row-gap + px;
    }
  }
  @each $row-gap in $gap-values {
    @at-root .spaced-bottom-#{$row-gap}#{&} {
      padding-bottom: $row-gap + px;
    }
  }
  /* /Gaps */
  &.small-spaced {
    padding-top: $small-spaced-gap;
    padding-bottom: $small-spaced-gap;
  }
  &.small-spaced-bottom {
    padding-bottom: $small-spaced-gap;
  }
  &.small-spaced-top {
    padding-top: $small-spaced-gap;
  }
  &.semi-spaced {
    padding-top: $semi-spaced-gap;
    padding-bottom: $semi-spaced-gap;
  }
  &.semi-spaced-bottom {
    padding-bottom: $semi-spaced-gap;
  }
  &.semi-spaced-top {
    padding-top: $semi-spaced-gap;
  }
  &.spaced {
    padding-top: $spaced-gap;
    padding-bottom: $spaced-gap;
  }
  &.spaced-bottom {
    padding-bottom: $spaced-gap;
  }
  &.spaced-top {
    padding-top: $spaced-gap;
  }
  &.large-spaced {
    padding-top: $large-spaced-gap;
    padding-bottom: $large-spaced-gap;
  }
  &.large-spaced-bottom {
    padding-bottom: $large-spaced-gap;
  }
  &.large-spaced-top {
    padding-top: $large-spaced-gap;
  }
  &.extra-large-spaced {
    padding-top: $extra-large-spaced-gap;
    padding-bottom: $extra-large-spaced-gap;
  }
  &.extra-large-spaced-top {
    padding-top: $extra-large-spaced-gap;
  }
  &.extra-large-spaced-bottom {
    padding-bottom: $extra-large-spaced-gap;
  }
  &.mega-spaced {
    padding-top: $mega-spaced-gap;
    padding-bottom: $mega-spaced-gap;
  }
  &.mega-spaced-bottom {
    padding-bottom: $mega-spaced-gap;
  }
  &.mega-spaced-top {
    padding-top: $mega-spaced-gap;
  }
  &.giant-spaced {
    padding-top: $giant-spaced-gap;
    padding-bottom: $giant-spaced-gap;
  }
  &.giant-spaced-bottom {
    padding-bottom: $giant-spaced-gap;
  }
  &.giant-spaced-top {
    padding-top: $giant-spaced-gap;
  }
  > .rt-column {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 0;
    min-width: 0;
    &.auto {
      flex: auto;
    }
    @at-root .fill-row#{&} {
      flex-grow: 1;
    }
    /* Gaps */
    @each $row-gap in $gap-values {
      @at-root .gap-#{$row-gap}#{&} {
        padding-left: ($row-gap * 0.5) + px;
        padding-right: ($row-gap * 0.5) + px;
      }
    }
    /* /Gaps */
    /* Vertical Gaps Follow Horizontal */
    @each $row-gap in $gap-values {
      @at-root .vertical-gap.gap-#{$row-gap}#{&} {
        margin-bottom: $row-gap + px;
      }
    }
    /* /Gaps */
    display: flex;
    align-content: stretch;
    align-items: stretch;
    .rt-column-content {
      display: block;
      width: 100%;
      flex: initial;
      @at-root .border-boxes#{&} {
        border: 1px solid #ccc;
        padding: $column-box-padding;
      }
      &[style*="background-image"] {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
      }
    }
    &.v-middle {
      align-self: center;
    }
    &.v-top {
      align-self: flex-start;
    }
    &.v-bottom {
      align-self: flex-end;
    }
    &.v-stretch {
      align-self: stretch;
    }
  }
}

/* Handy layout building stuff */
.rt-row {
  @each $row-gap in $gap-values {
    @at-root .gap-#{$row-gap}#{&} {
      > .rt-column {
        padding-bottom: $row-gap + px;
        &.no-propagate {
          padding-bottom: 0;
        }
      }
    }
  }
}

@media (max-width: $laptop-screen) {
  .rt-row {
    &.small-spaced {
      padding-top: math.div($small-spaced-gap, 1.2);
      padding-bottom: math.div($small-spaced-gap, 1.2);
    }
    &.small-spaced-bottom {
      padding-bottom: math.div($small-spaced-gap, 1.2);
    }
    &.small-spaced-top {
      padding-top: math.div($small-spaced-gap, 1.2);
    }
    &.semi-spaced {
      padding-top: math.div($semi-spaced-gap, 1.2);
      padding-bottom: math.div($semi-spaced-gap, 1.2);
    }
    &.semi-spaced-bottom {
      padding-bottom: math.div($semi-spaced-gap, 1.2);
    }
    &.semi-spaced-top {
      padding-top: math.div($semi-spaced-gap, 1.2);
    }
    &.spaced {
      padding-top: math.div($spaced-gap, 1.2);
      padding-bottom: math.div($spaced-gap, 1.2);
    }
    &.spaced-bottom {
      padding-bottom: math.div($spaced-gap, 1.2);
    }
    &.spaced-top {
      padding-top: math.div($spaced-gap, 1.2);
    }
    &.large-spaced {
      padding-top: math.div($large-spaced-gap, 1.2);
      padding-bottom: math.div($large-spaced-gap, 1.2);
    }
    &.large-spaced-bottom {
      padding-bottom: math.div($large-spaced-gap, 1.2);
    }
    &.large-spaced-top {
      padding-top: math.div($large-spaced-gap, 1.2);
    }
    &.extra-large-spaced {
      padding-top: math.div($extra-large-spaced-gap, 1.2);
      padding-bottom: math.div($extra-large-spaced-gap, 1.2);
    }
    &.extra-large-spaced-top {
      padding-top: math.div($extra-large-spaced-gap, 1.2);
    }
    &.giant-spaced {
      padding-top: math.div($giant-spaced-gap, 1.2);
      padding-bottom: math.div($giant-spaced-gap, 1.2);
    }
    &.giant-spaced-bottom {
      padding-bottom: math.div($giant-spaced-gap, 1.2);
    }
    &.giant-spaced-top {
      padding-top: math.div($giant-spaced-gap, 1.2);
    }
  }
}

@media (max-width: $tablet-screen) {
  .rt-row {
    &.small-spaced {
      padding-top: math.div($small-spaced-gap, 1.4);
      padding-bottom: math.div($small-spaced-gap, 1.4);
    }
    &.small-spaced-bottom {
      padding-bottom: math.div($small-spaced-gap, 1.4);
    }
    &.small-spaced-top {
      padding-top: math.div($small-spaced-gap, 1.4);
    }
    &.semi-spaced {
      padding-top: math.div($semi-spaced-gap, 1.4);
      padding-bottom: math.div($semi-spaced-gap, 1.4);
    }
    &.semi-spaced-bottom {
      padding-bottom: math.div($semi-spaced-gap, 1.4);
    }
    &.semi-spaced-top {
      padding-top: math.div($semi-spaced-gap, 1.4);
    }
    &.spaced {
      padding-top: math.div($spaced-gap, 1.4);
      padding-bottom: math.div($spaced-gap, 1.4);
    }
    &.spaced-bottom {
      padding-bottom: math.div($spaced-gap, 1.4);
    }
    &.spaced-top {
      padding-top: math.div($spaced-gap, 1.4);
    }
    &.large-spaced {
      padding-top: math.div($large-spaced-gap, 1.4);
      padding-bottom: math.div($large-spaced-gap, 1.4);
    }
    &.large-spaced-bottom {
      padding-bottom: math.div($large-spaced-gap, 1.4);
    }
    &.large-spaced-top {
      padding-top: math.div($large-spaced-gap, 1.4);
    }
    &.extra-large-spaced {
      padding-top: math.div($extra-large-spaced-gap, 1.4);
      padding-bottom: math.div($extra-large-spaced-gap, 1.4);
    }
    &.extra-large-spaced-top {
      padding-top: math.div($extra-large-spaced-gap, 1.4);
    }
    &.giant-spaced {
      padding-top: math.div($giant-spaced-gap, 1.4);
      padding-bottom: math.div($giant-spaced-gap, 1.4);
    }
    &.giant-spaced-bottom {
      padding-bottom: math.div($giant-spaced-gap, 1.4);
    }
    &.giant-spaced-top {
      padding-top: math.div($giant-spaced-gap, 1.4);
    }
  }
}

@media (max-width: $phablet-screen) {
  .rt-row {
    &.small-spaced {
      padding-top: math.div($small-spaced-gap, 1.65);
      padding-bottom: math.div($small-spaced-gap, 1.65);
    }
    &.small-spaced-bottom {
      padding-bottom: math.div($small-spaced-gap, 1.65);
    }
    &.small-spaced-top {
      padding-top: math.div($small-spaced-gap, 1.65);
    }
    &.semi-spaced {
      padding-top: math.div($semi-spaced-gap, 1.65);
      padding-bottom: math.div($semi-spaced-gap, 1.65);
    }
    &.semi-spaced-bottom {
      padding-bottom: math.div($semi-spaced-gap, 1.65);
    }
    &.semi-spaced-top {
      padding-top: math.div($semi-spaced-gap, 1.65);
    }
    &.spaced {
      padding-top: math.div($spaced-gap, 1.65);
      padding-bottom: math.div($spaced-gap, 1.65);
    }
    &.spaced-bottom {
      padding-bottom: math.div($spaced-gap, 1.65);
    }
    &.spaced-top {
      padding-top: math.div($spaced-gap, 1.65);
    }
    &.large-spaced {
      padding-top: math.div($large-spaced-gap, 1.65);
      padding-bottom: math.div($large-spaced-gap, 1.65);
    }
    &.large-spaced-bottom {
      padding-bottom: math.div($large-spaced-gap, 1.65);
    }
    &.large-spaced-top {
      padding-top: math.div($large-spaced-gap, 1.65);
    }
    &.extra-large-spaced {
      padding-top: math.div($extra-large-spaced-gap, 1.65);
      padding-bottom: math.div($extra-large-spaced-gap, 1.65);
    }
    &.extra-large-spaced-top {
      padding-top: math.div($extra-large-spaced-gap, 1.65);
    }
    &.giant-spaced {
      padding-top: math.div($giant-spaced-gap, 1.65);
      padding-bottom: math.div($giant-spaced-gap, 1.65);
    }
    &.giant-spaced-bottom {
      padding-bottom: math.div($giant-spaced-gap, 1.65);
    }
    &.giant-spaced-top {
      padding-top: math.div($giant-spaced-gap, 1.65);
    }
  }
}

@media (max-width: $phone-screen) {
  .rt-row {
    &.small-spaced {
      padding-top: math.div($small-spaced-gap, 1.9);
      padding-bottom: math.div($small-spaced-gap, 1.9);
    }
    &.small-spaced-bottom {
      padding-bottom: math.div($small-spaced-gap, 1.9);
    }
    &.small-spaced-top {
      padding-top: math.div($small-spaced-gap, 1.9);
    }
    &.semi-spaced {
      padding-top: math.div($semi-spaced-gap, 1.9);
      padding-bottom: math.div($semi-spaced-gap, 1.9);
    }
    &.semi-spaced-bottom {
      padding-bottom: math.div($semi-spaced-gap, 1.9);
    }
    &.semi-spaced-top {
      padding-top: math.div($semi-spaced-gap, 1.9);
    }
    &.spaced {
      padding-top: math.div($spaced-gap, 1.9);
      padding-bottom: math.div($spaced-gap, 1.9);
    }
    &.spaced-bottom {
      padding-bottom: math.div($spaced-gap, 1.9);
    }
    &.spaced-top {
      padding-top: math.div($spaced-gap, 1.9);
    }
    &.large-spaced {
      padding-top: math.div($large-spaced-gap, 1.9);
      padding-bottom: math.div($large-spaced-gap, 1.9);
    }
    &.large-spaced-bottom {
      padding-bottom: math.div($large-spaced-gap, 1.9);
    }
    &.large-spaced-top {
      padding-top: math.div($large-spaced-gap, 1.9);
    }
    &.extra-large-spaced {
      padding-top: math.div($extra-large-spaced-gap, 1.9);
      padding-bottom: math.div($extra-large-spaced-gap, 1.9);
    }
    &.extra-large-spaced-top {
      padding-top: math.div($extra-large-spaced-gap, 1.9);
    }
    &.giant-spaced {
      padding-top: math.div($giant-spaced-gap, 1.9);
      padding-bottom: math.div($giant-spaced-gap, 1.9);
    }
    &.giant-spaced-bottom {
      padding-bottom: math.div($giant-spaced-gap, 1.9);
    }
    &.giant-spaced-top {
      padding-top: math.div($giant-spaced-gap, 1.9);
    }
  }
}

.rt-column {
  min-width: 0;
  min-height: 0;
  &.desk-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  &.desk-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  &.desk-8 {
    flex-basis: 66.66%;
    max-width: 66.66%;
  }
  &.desk-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  &.desk-4 {
    flex-basis: 33.33%;
    max-width: 33.33%;
  }
  &.desk-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  &.desk-2-4 {
    flex-basis: 20%;
    max-width: 20%;
  }
  &.desk-2 {
    flex-basis: 16.66%;
    max-width: 16.66%;
  }
  &.desk-1 {
    flex-basis: 8.33%;
    max-width: 8.33%;
  }
  &.desk-none {
    display: none;
  }
  &.desk-auto {
    flex: auto;
  }
  &.desk-initial {
    flex: initial;
  }
  @media (max-width: $laptop-screen) {
    &.lap-12 {
      flex-basis: 100%;
      max-width: 100%;
    }
    &.lap-9 {
      flex-basis: 75%;
      max-width: 75%;
    }
    &.lap-8 {
      flex-basis: 66.66%;
      max-width: 66.66%;
    }
    &.lap-6 {
      flex-basis: 50%;
      max-width: 50%;
    }
    &.lap-4 {
      flex-basis: 33.33%;
      max-width: 33.33%;
    }
    &.lap-3 {
      flex-basis: 25%;
      max-width: 25%;
    }
    &.lap-2-4 {
      flex-basis: 20%;
      max-width: 20%;
    }
    &.lap-2 {
      flex-basis: 16.66%;
      max-width: 16.66%;
    }
    &.lap-1 {
      flex-basis: 8.33%;
      max-width: 8.33%;
    }
    &.lap-none {
      display: none;
    }
    &[class*="lap-"]:not(.lap-none) {
      display: flex;
    }
    &.lap-auto {
      flex: auto;
    }
    &.lap-initial {
      flex: initial;
    }
  }
  @media (max-width: $tablet-screen) {
    &.tab-12 {
      flex-basis: 100%;
      max-width: 100%;
    }
    &.tab-9 {
      flex-basis: 75%;
      max-width: 75%;
    }
    &.tab-8 {
      flex-basis: 66.66%;
      max-width: 66.66%;
    }
    &.tab-6 {
      flex-basis: 50%;
      max-width: 50%;
    }
    &.tab-4 {
      flex-basis: 33.33%;
      max-width: 33.33%;
    }
    &.tab-3 {
      flex-basis: 25%;
      max-width: 25%;
    }
    &.tab-2-4 {
      flex-basis: 20%;
      max-width: 20%;
    }
    &.tab-2 {
      flex-basis: 16.66%;
      max-width: 16.66%;
    }
    &.tab-1 {
      flex-basis: 8.33%;
      max-width: 8.33%;
    }
    &.tab-none {
      display: none;
    }
    &[class*="tab-"]:not(.tab-none) {
      display: flex;
    }
    &.tab-auto {
      flex: auto;
    }
    &.tab-initial {
      flex: initial;
    }
  }
  @media (max-width: $phablet-screen) {
    &.phab-12 {
      flex-basis: 100%;
      max-width: 100%;
    }
    &.phab-9 {
      flex-basis: 75%;
      max-width: 75%;
    }
    &.phab-8 {
      flex-basis: 66.66%;
      max-width: 66.66%;
    }
    &.phab-6 {
      flex-basis: 50%;
      max-width: 50%;
    }
    &.phab-4 {
      flex-basis: 33.33%;
      max-width: 33.33%;
    }
    &.phab-3 {
      flex-basis: 25%;
      max-width: 25%;
    }
    &.phab-2-4 {
      flex-basis: 20%;
      max-width: 20%;
    }
    &.phab-2 {
      flex-basis: 16.66%;
      max-width: 16.66%;
    }
    &.phab-1 {
      flex-basis: 8.33%;
      max-width: 8.33%;
    }
    &.phab-none {
      display: none;
    }
    &[class*="phab-"]:not(.phab-none) {
      display: flex;
    }
    &.phab-auto {
      flex: auto;
    }
    &.phab-initial {
      flex: initial;
    }
  }
  @media (max-width: $phone-screen) {
    &.phone-12 {
      flex-basis: 100%;
      max-width: 100%;
    }
    &.phone-9 {
      flex-basis: 75%;
      max-width: 75%;
    }
    &.phone-8 {
      flex-basis: 66.66%;
      max-width: 66.66%;
    }
    &.phone-6 {
      flex-basis: 50%;
      max-width: 50%;
    }
    &.phone-4 {
      flex-basis: 33.33%;
      max-width: 33.33%;
    }
    &.phone-3 {
      flex-basis: 25%;
      max-width: 25%;
    }
    &.phone-2-4 {
      flex-basis: 20%;
      max-width: 20%;
    }
    &.phone-2 {
      flex-basis: 16.66%;
      max-width: 16.66%;
    }
    &.phone-1 {
      flex-basis: 8.33%;
      max-width: 8.33%;
    }
    &.phone-none {
      display: none;
    }
    &[class*="phone-"]:not(.phone-none) {
      display: flex;
    }
    &.phone-auto {
      flex: auto;
    }
    &.phone-initial {
      flex: initial;
    }
  }
}

.rt-divider {
  &.border {
    &:before {
      display: block;
      content: ' ';
      height: 1px;
      background-color: rgba(0,0,0,0.15);
    }
  }
  /* Custom spaces */
  //@each $row-gap in $gap-values {
  //  @at-root .spaced-top-#{$row-gap}#{&} {
  //    padding-top: $row-gap + px;
  //  }
  //}
  @each $row-gap in $gap-values {
    @at-root .spaced-bottom-#{$row-gap}#{&} {
      padding-bottom: $row-gap + px;
    }
  }
  &.desk-tiny {
    padding: 0.5em 0;
  }
  &.desk-small {
    padding: 0.75em 0;
  }
  &.desk-medium {
    padding: 1em 0;
  }
  &.desk-big {
    padding: 1.25em 0;
  }
  &.desk-large {
    padding: 1.5em 0;
  }
  &.desk-none {
    display: none;
  }
  &.desk-tiny, &.desk-small, &.desk-medium, &.desk-big, &.desk-large {
    display: block;
    flex: 1 1 100%;
  }
  @media (max-width: $laptop-screen) {
    &.lap-tiny {
      padding: 0.5em 0;
    }
    &.lap-small {
      padding: 0.75em 0;
    }
    &.lap-medium {
      padding: 1em 0;
    }
    &.lap-big {
      padding: 1.25em 0;
    }
    &.lap-large {
      padding: 1.5em 0;
    }
    &.lap-none {
      display: none;
    }
    &.lap-tiny, &.lap-small, &.lap-medium, &.lap-big, &.lap-large {
      display: block;
      flex: 1 1 100%;
    }
  }
  @media (max-width: $tablet-screen) {
    &.tab-tiny {
      padding: 0.5em 0;
    }
    &.tab-small {
      padding: 0.75em 0;
    }
    &.tab-medium {
      padding: 1em 0;
    }
    &.tab-big {
      padding: 1.25em 0;
    }
    &.tab-large {
      padding: 1.5em 0;
    }
    &.tab-none {
      display: none;
    }
    &.tab-tiny, &.tab-small, &.tab-medium, &.tab-big, &.tab-large {
      display: block;
      flex: 1 1 100%;
    }
  }
  @media (max-width: $phablet-screen) {
    &.phab-tiny {
      padding: 0.5em 0;
    }
    &.phab-small {
      padding: 0.75em 0;
    }
    &.phab-medium {
      padding: 1em 0;
    }
    &.phab-big {
      padding: 1.25em 0;
    }
    &.phab-large {
      padding: 1.5em 0;
    }
    &.phab-none {
      display: none;
    }
    &.phab-tiny, &.phab-small, &.phab-medium, &.phab-big, &.phab-large {
      display: block;
      flex: 1 1 100%;
    }
  }
  @media (max-width: $phone-screen) {
    &.phone-tiny {
      padding: 0.5em 0;
    }
    &.phone-small {
      padding: 0.75em 0;
    }
    &.phone-medium {
      padding: 1em 0;
    }
    &.phone-big {
      padding: 1.25em 0;
    }
    &.phone-large {
      padding: 1.5em 0;
    }
    &.phone-none {
      display: none;
    }
    &.phone-tiny, &.phone-small, &.phone-medium, &.phone-big, &.phone-large {
      display: block;
      flex: 1 1 100%;
    }
  }
}
