
.search-header {
  padding: 32px;
  border-radius: $configRadiusHigh;
  text-align: center;
  background: 
    linear-gradient(270deg, rgba(32, 11, 57, 0) 0%, $riterzSecondary 80%), 
    url('/assets/marketplace-background.jpeg') 0 -178px no-repeat;
  background-size: cover;

  h1 { 
    color: #fff;
    font-size: 64px;
    line-height: 72px;
    margin: 0;
  }

  .subtitle {
    color: #fff;
    margin: 24px;
    font-size: 24px;
    line-height: 32px;
  }

  .search-type {
    color: #fff;
    padding: 8px;
    font-size: 16px;
    color: $riterzSecondaryLighter;

    .search-option {
      display: inline-block;
      position: relative;
      padding: 8px 20px;  
      cursor: pointer;  

      &.active {
        color: #fff;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: #fff;
        }
      }
    }
  }

  .search-form {
    display: inline-flex;
    background: #ffffff40;
    border-radius: 30px;
    margin: 20px auto;
    padding: 8px;
    width: 100%;
    max-width: 720px;
    gap: 8px;

    .search-input {
      flex-grow: 1;
      display: inline-flex;

      > .p-element {
        flex: 1 1 100%;
      }

      > .p-inputtext {
        border-radius: 30px 0 0 30px;
      }

      .p-multiselect {
        width: 100%;
        height: 36px;
        border-left: 0;
        border-radius: 0 30px 30px 0;

        .p-multiselect-label {
          line-height: 22px;
        }
      }
    }
  }

  .rt-search-filter {
    display: inline-flex;
    align-items: center;
    height: 24px;
    padding: 0 0 0 12px;
    margin: 4px;
    background-color: #fff;
    border-radius: 16px;
    border: 1px solid var(--Secondary-Default, #200B39);
    background: var(--Gray-0, #FFF);
  }
}

.searched-organizations-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h2 {
    margin: 0;
  }
}

.rt-table {
  a {
    color: $riterzSecondary;
  }

  tr {
    transition: all 200ms ease;

    &.rt-clickable:hover {
      background-color: #fafafa;
    }    
  }


  .rt-pills {
    max-height: 50px;

    .rt-pill {
      height: 20px;
      display: inline-block;
      padding: 4px 8px;
      border-radius: 10px;
      background: var(--Gray-5, #F1F1F1);
      color: var(--Secondary-Default, #200B39);
      text-align: center;
      font-family: Montserrat;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 15.6px */
    }  
  }

}