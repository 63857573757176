
/* Loading element & animation */
.rt-loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1001;
  background-color: #f2f2f2;
  @extend .center-flex;
  opacity: 0;
  pointer-events: none;
  transition: all 300ms ease;
  .rt-spinner {
    max-width: 40px;
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
    margin: 100px auto;
    background-color: $navusPrimary;
    border-radius: 100%;
    animation: rt-scale-out 1.0s infinite ease-in-out;
  }
  &.on {
    opacity: 1;
    pointer-events: auto;
  }
}


@-webkit-keyframes rt-scale-out {
  0% { 
    -webkit-transform: scale(0) 
  }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes rt-scale-out {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 
  100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}